import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  Paragraph,
  PlatformTab,
  Section,
  SectionSubhead,
  PropList,
  PropListItem,
  PageNavigation,
  PageNavigationLink,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';

const WebTab = () => {
  return (
    <PlatformTab>
      <PageNavigation>
        <PageNavigationLink>Uniform Type</PageNavigationLink>
        <PageNavigationLink>Type Size</PageNavigationLink>
        <PageNavigationLink>Line Height</PageNavigationLink>
      </PageNavigation>
      <Section title="Uniform Type">
        <Paragraph>
          This mixin enables you to use Uniform type styles on classes within
          your own code, if you’re unable to take advantage of the{' '}
          <Link isDesignCodeLink href="/components/type">
            type components
          </Link>
          .
        </Paragraph>
        <CodeSnippet
          code="@include uniform-type( $variant );"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.type.scss"
        />

        <Section title="Parameters">
          <PropList>
            <PropListItem name="$variant" types={['list-map value']}>
              <Text>
                Default value: <code>none</code>
              </Text>
              <Text>Pass one of the following values.</Text>
              <List type="unordered">
                <li>headline-1</li>
                <li>headline-2</li>
                <li>headline-3</li>
                <li>subhead</li>
                <li>subhead-small</li>
                <li>text-large</li>
                <li>text-text</li>
                <li>text-small</li>
                <li>text-micro</li>
                <li>text-additional</li>
                <li>item-title</li>
                <li>text-title-small</li>
              </List>
            </PropListItem>
          </PropList>

          <div className="uni-margin--two--top">
            <SectionSubhead>Notes</SectionSubhead>
            <Paragraph>
              The list map value passed to the mixin sets the following
              attributes of the type:
            </Paragraph>
            <List type="unordered">
              <li>
                <code>font-family</code>
              </li>
              <li>
                <code>font-weight</code>
              </li>
              <li>
                <code>font-size</code>
              </li>
              <li>
                <code>line-height</code>
              </li>
              <li>
                <code>color</code>
              </li>
              <li>
                <code>letter-spacing</code>
              </li>
            </List>
          </div>
        </Section>
      </Section>
      <Section title="Type Size">
        <Paragraph>
          The type-size mixin automatically switches type from a smaller,
          hand-held appropriate size, to a larger, tablet and up appropriate
          size.
        </Paragraph>

        <CodeSnippet
          code="@include type-size($default-size, $small-size);"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.type.size.scss#L18"
        />

        <Section title="Parameters">
          <PropList>
            <PropListItem name="$default-size" types={['number']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>This is larger (above 600px) size in the range.</Text>
            </PropListItem>

            <PropListItem name="$small-size" types={['number']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>This is the size for screens 600px and smaller.</Text>
            </PropListItem>
          </PropList>

          <div className="uni-margin--two--top">
            <SectionSubhead>Notes</SectionSubhead>
            <List type="unordered">
              <li>
                The type-size mixin was created for use within the Uniform type
                system. Specifically, for large, display type. As such, it is
                written specifically for that use case. The 37.5rem (600px)
                breakpoint for the size change is hard-coded within the mixin.
              </li>
            </List>
          </div>
        </Section>
      </Section>
      <Section title="Line Height">
        <CodeSnippet
          code="@include line-height($default-line-height, $small-line-height);"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.type.size.scss#L37"
        />

        <Section title="Parameters">
          <PropList>
            <PropListItem name="$default-line-height" types={['number']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>This is the line height for the large type size.</Text>
            </PropListItem>

            <PropListItem name="$small-line-height" types={['number']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>The is the line height for the smaller size type.</Text>
            </PropListItem>
          </PropList>

          <div className="uni-margin--two--top">
            <SectionSubhead>Notes</SectionSubhead>
            <List type="unordered">
              <li>
                The line-height mixin was created for use within the Uniform
                type system. Specifically, for large, display type. As such, it
                is written specifically for that use case. The 37.5rem (600px)
                breakpoint for the size change is hard-coded within the mixin.
              </li>
            </List>
          </div>
        </Section>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
